.wait-list-view {
	margin: 2rem 0 0;

	&__heading {
		font-size: 1.125rem;
	}

	&__game {
		margin: 0 0 2rem;
	}

	&__game-head {
		display: flex;
		justify-content: space-between;
		margin: 0 0 .5rem;
		line-height: 1.3rem;
		color: #ddd3b9;

		h3 {
			font-size: 1rem;
			margin: 0;
		}

		h3, strong {
			color: #fff;
		}
	}

	&__status-warning {
		background-color: #dc1616;
		color: #fff;
		padding: 0 .5rem;
		font-weight: bold;
	}

	&__list {
		text-align: left;
		list-style-type: none;
		margin: 0;
		padding: 0;
		border: 1px solid #666256;
		border-radius: 3px;
		font-size: .875rem;
		line-height: 1rem;
	}

	&__item {
		border-bottom: 1px solid #666256;
		padding: 3px 10px;

		&--mine {
			background-color: #322;
		}

		&:last-child {
			border-bottom: none;
		}

		&__index {
			display: inline-block;
			width: 1.6em;
			color: #999281;
		}

		&__time {
			color: #beb5a0;
		}

		&__game {
			color: #7d8;
			font-weight: bold;

			@media (max-width: 500px) {
				display: none;
			}
		}

		&__reward {
			color: #999281;
		}
	}
}

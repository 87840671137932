.game-view {
	padding: 1rem 1.5rem 0;

	&__text-link {
		font-weight: bold;
		color: #fff;

		&:hover {
			color: lighten(#ddd3b9, 10%);
		}
	}

	&__join-button {
		margin: 2rem 0;
		display: block;
		background-color: #691c7f;
		color: #fff;
		text-decoration: none;
		font-size: 1.2rem;
		font-weight: bold;
		text-transform: uppercase;
		padding: .8rem 1rem;
		border-radius: 5px;

		&:hover {
			background-color: lighten(#691c7f, 10%);
		}
	}

	&__code-container {
		position: relative;
		width: 13.25rem;
		margin: 0 auto;

		@media (min-width: 500px) {
			width: 18rem;
		}
	}

	&__code {
		font-family: inherit;
		color: #fff;
		background-color: #111;
		border: 1px solid #523e14;
		border-radius: 3px;
		font-size: 2rem;
		font-weight: bold;
		width: 9rem;
		padding: 0 3.5rem 0 .5rem;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.125em;

		@media (min-width: 500px) {
			font-size: 3rem;
			width: 14rem;
		}
	}

	&__copy-button {
		position: absolute;
		top: .5625rem;
		right: .625rem;
		border: none;
		appearance: none;
		background-color: #691c7f;
		color: #fff;
		padding: .2rem .5rem;
		font-family: inherit;
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 3px;
		cursor: pointer;

		@media (min-width: 500px) {
			top: 1.125rem;
			right: 1rem;
		}
	}

	&__code-copied {
		position: absolute;
		top: -1.75rem;
		right: -1rem;
		background-color: #5ff2ff;
		color: #691c7f;
		font-size: .875rem;
		font-weight: bold;
		padding: .2rem .5rem;
		border-radius: 3px;
		opacity: 0;
		will-change: opacity;
		transition: .25s opacity;
		pointer-events: none;

		@media (min-width: 500px) {
			top: -1.25rem;
			right: -.5rem;
		}

		&__visible {
			opacity: 1;
		}
	}
}

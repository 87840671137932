.site {
	&-inner-container {
		max-width: 40rem;
		margin: 2rem auto 5rem;
		padding: 0 1rem;

		@media (max-width: 400px) {
			margin: 1rem auto 4rem;
		}
	}

	&-primary {
		border: 1px solid #523e14;
		background-color: #322;
		padding: 1rem 2rem;
		margin: 0 0 1rem;
		border-radius: 3px;

		@media (max-width: 400px) {
			padding: .5rem 1rem;
		}

		p strong {
			color: #fff;
		}
	}

	&-secondary {
		color: #999281;
		font-size: .8125rem;
		line-height: 1.3rem;
		margin: 1.5rem 0 0;
		padding: 0 2rem 0;

		strong {
			color: #bfb6a1;
		}

		a {
			color: inherit;

			&:hover {
				color: #bfb6a1;
			}
		}

		@media (max-width: 400px) {
			padding: 0 1rem;
		}
	}

	&-title {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.75rem;
		background-color: #322;
		color: #e6c876;
		margin: 0;
		padding: .5em 0;
		text-transform: uppercase;

		img {
			width: 2rem;
			height: 2rem;
			margin: 0 .5rem 0 0;
		}

		@media (max-width: 400px) {
			font-size: 1.5rem;
		}
	}

	&-prelude {
		background: #691c7f url(../../../public/epjb-site-top-banners.png) no-repeat center -5px;
		background-size: 300px 40px;
		font-size: 1rem;
		font-weight: normal;
		color: #fff;
		margin: 0;
		padding: 30px 0 .25em;

		@media (max-width: 400px) {
			background-size: 200px 27px;
			background-position: center -3px;
			padding-top: 24px;
		}
	}

	&-secondary-text {
		color: #999281;
		font-size: .8125rem;
		line-height: 1.3rem;

		p strong {
			color: #bfb6a1;
		}
	}
}

body.page {
	background: #691c7f url(../../../public/epjb-site-top-banners.png) no-repeat center -5px;
	background-size: 300px 40px;
	margin: 3rem 0;
	color: #e4d6e8;

	.page-container {
		max-width: 40rem;
		padding: 0 1rem;
		margin: 0 auto;
		text-align: left;

		strong, a {
			color: #fff;
		}

		h1, h2, h3, h4, h5, h6 {
			color: #fef83c;
		}

		h1 {
			font-size: 2rem;
			line-height: 2.4rem;
		}

		h2 {
			margin-top: 3rem;
			font-size: 1.5rem;
			line-height: 2rem;
		}

		li {
			margin: 0 0 1rem;
		}

		.emote {
			vertical-align: middle;
		}

		.signoff {
			margin-top: 3rem;
		}
	}
}

.connect-view {
	&__connect-button {
		margin: 1.5rem 0;
		font-family: inherit;
		width: 100%;
		border: none;
		cursor: pointer;
		display: block;
		background-color: lighten(#691c7f, 10%);
		color: #fff;
		text-decoration: none;
		font-size: 1.2rem;
		font-weight: bold;
		text-transform: uppercase;
		padding: .8rem 1rem;
		border-radius: 5px;

		&:hover {
			background-color: lighten(#691c7f, 20%);
		}
	}

	&__warning {
		background-color: #dc1616;
		color: #e4e4e4;
		font-size: .8125rem;
		line-height: 1.3rem;
		padding: .5rem 1rem;
		margin: 0 0 1rem;

		strong {
			color: #fff;
		}
	}
}
